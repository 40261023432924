<template lang="pug">
.px-4(style="width: 100%")
  .brand-mobile-onboarding-title-sm {{ $t('mobileOnboarding.titles.goals') }}
  ButtonGroup(:items="goals" translationKeyBase="templateFilter.goals" @clicked="onClick")
</template>

<script>
  import { steps, goalsByIndustry } from '@/utils/mobileOnboarding';
  import mobileOnboarding from '@/mixins/mobileOnboarding';
  import saveStep from '@/mixins/saveOnboardingProgress';
  import SEND_USER_QUALIFICATIONS from '@/graphql/SendUserQualifications.gql';
  import { mapActions } from 'vuex';
  import ButtonGroup from '../ButtonGroup.vue';

  export default {
    name: 'MobileOnboardingGoals',
    components: { ButtonGroup },
    mixins: [saveStep, mobileOnboarding],
    data: () => ({ loading: true }),
    computed: {
      goals() {
        return goalsByIndustry[this.industry] || goalsByIndustry.ecommerce; // TODO default goals list
      },
    },
    async created() {
      if (!this.industry) {
        this.$router.replace({ name: steps.WELCOME.name });
      } else {
        await this.$apollo.mutate({
          mutation: SEND_USER_QUALIFICATIONS,
          variables: {
            business: this.industry,
            stage: this.stage || '',
            whyUs: '',
            onboardingVersion: 'newMobile0227',
          },
        });
      }
    },
    methods: {
      ...mapActions(['finishOnboarding']),
      async onClick(goal) {
        await this.finishOnboarding();
        localStorage.removeItem('om-onboarding-industry');
        localStorage.removeItem('om-onboarding-income');
        this.$router.push({
          name: 'templates',
          query: { goals: JSON.stringify([goal]) },
        });
      },
    },
  };
</script>
